
import { computed, defineComponent, onMounted, ref, watch, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import contacts from "@/core/data/contacts";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

interface RequestForm {
  address: string;
  bedrooms: number;
  beds: number;
  floor: number;
  choices: number;
}

export default defineComponent({
  name: "private-chat",

  setup() {
    const store = useStore();

    let advert = computed(() => {
      return store.getters.currentResultsV3;
    });

    let loader = ref<boolean>(false)
    const route = useRoute();

    const formData = ref<RequestForm>({
      address: "",
      bedrooms: 0,
      beds: 0,
      floor: 0,
      choices: 3,
    });

    const requestNewAdvert = async (values) => {
      loader.value = true;
      
      for (const item in values) {
        // eslint-disable-next-line no-prototype-builtins
        if (values.hasOwnProperty(item)) {
          if (values[item]) {
            formData.value[item] = values[item];
          }
        }
      }
      await store
        .dispatch(Actions.GET_ADVERT_V3, formData.value)
        .then((resp) => {
          console.log("Received response");
        }).catch(() =>{
          loader.value = false;
        });
      loader.value = false;
    };
    const setPlace = (addressData, placeResultData, id) => {
      formData.value["address"] = addressData.formatted_address;
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Chat"]);
    });

    return {
      loader,
      requestNewAdvert,
      setPlace,
      formData,
      advert,
    };
  },
});
